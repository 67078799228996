<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card px-3">
        <v-toolbar dense flat class="pa-3 mb-3">
          <h3>Colleaguess</h3>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row justify="end">
          <v-col cols="12" md="6">
            <v-text-field
              solo
              dense
              type="search"
              autocomplete="off"
              label="Cari"
              rounded
              append-icon="mdi-magnify"
              hide-details
              flat
              v-model="find"
              background-color="#ECEFF1"
              @keyup="fetchColleaguess(find == '' ? false : true)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-list three-line v-if="kolega">
          <template v-for="(item, index) in kolega">
            <v-divider :key="`${index}-slahs`"></v-divider>

            <v-list-item :key="item.id">
              <v-list-item-avatar size="50">
                <img
                  :src="
                    item.photo_profile
                      ? `${env}/upload/photo_profile/${item.id}/${item.photo_profile}`
                      : dummy
                  "
                  alt=""
                  class="img-fit"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-html="item.nama_lengkap"
                ></v-list-item-title>
                <div
                  v-for="(relation, idx) in item.relationships"
                  :key="`relate${idx}`"
                >
                  <div v-if="relation.bidang">
                    <v-list-item-subtitle v-if="relation.bidang.data">
                      {{ relation.bidang.data.nama }}
                    </v-list-item-subtitle>
                  </div>
                  <v-list-item-subtitle v-if="relation.kota1">{{
                    relation.kota1.data.nama_kota
                  }}</v-list-item-subtitle>
                </div>
                <router-link :to="`/pam/${item.id}`">
                  <v-btn outlined rounded color="blue" class="additional_"
                    >Lihat Profile</v-btn
                  >
                </router-link>
              </v-list-item-content>

              <v-list-item-action class="action__">
                <router-link :to="`/pam/${item.id}`">
                  <v-btn outlined rounded color="blue">Lihat Profile</v-btn>
                </router-link>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
        <div class="px-3" v-if="loading">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </div>
      </v-card>
      <div v-if="kolega" v-observe-visibility="handleScroll"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "colleaguess",
  computed: {
    ...mapState({
      kolega: state => state.colleaguess,
      last_page_kolega: state => state.last_page_kolega,
      env: state => state.API_URL,
      dummy: state => state.dummy
    })
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      loading: false,
      find: "",
      isFinding: false,
      theme: {
        default: { isDark: false }
      },
      inject: {}
    };
  },
  mounted() {
    this.fetchColleaguess(false);
  },
  methods: {
    fetchColleaguess(from) {
      this.isFinding = from;
      if (this.isFinding) {
        this.page = 1;
      }
      this.loading = true;
      this.$store
        .dispatch("listColleaguess", {
          page: this.page,
          per_page: this.perPage,
          name: this.find
        })
        .then(data => {
          if (data) {
            this.loading = false;
          }
        });
    },

    handleScroll(isVisible) {
      if (isVisible) {
        if (this.isFinding == false) {
          if (this.page <= this.last_page_kolega) {
            this.page++;
            this.fetchColleaguess(false);
          }
        }
      }
    },
    toView(item) {
      this.$router.push(`/pam/${item.id}`);
    }
  }
};
</script>

<style scoped>
.additional_ {
  display: none;
}
@media (max-width: 576px) {
  .additional_ {
    display: block;
  }
  .action__ {
    display: none;
  }
}
@media (max-width: 768px) {
  .additional_ {
    display: block;
  }
  .action__ {
    display: none;
  }
}
</style>
